import React, { createContext, useContext, useEffect, useState } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const msalConfig = {
  auth: {
    /*clientId: "62ff87ed-45d9-4318-af54-ba7d71fd8c76",
    authority: "https://login.microsoftonline.com/da5284b0-ca6e-4343-ba1a-24725c532d09",*/
    clientId: "788d8cc6-5086-4624-af32-765f2e36079f",
    authority: "https://login.microsoftonline.com/8075ed4b-7a84-4c5e-a066-370a8effc36b",
    redirectUri: "http://localhost:3000/login",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const loginRequest = {
  scopes: ["User.Read"],
};

const msalInstance = new PublicClientApplication(msalConfig);
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        console.log("Initializing MSAL...");
        await msalInstance.initialize();
        console.log("Initialized MSAL...");
        const response = await msalInstance.handleRedirectPromise();

        console.log("handleRedirectPromise response:", response);
        if (response) {
          setAccount(response.account);
        } else {
          const currentAccounts = msalInstance.getAllAccounts();
          console.log("currentAccounts:", currentAccounts);
          if (currentAccounts.length === 1) {
            setAccount(currentAccounts[0]);
            setRoles(currentAccounts[0].idTokenClaims.roles || []);
          }
        }
      } catch (error) {
        console.error("MSAL initialization or redirect error:", error);
      }
    };

    handleRedirect();
  }, []);


  const signIn = () => {
    msalInstance.loginRedirect(loginRequest);
  };

  const signOut = () => {
    msalInstance.logoutRedirect(); // use logoutRedirect instead of logout
  };

  return (
     <AuthContext.Provider value={{ account, roles, signIn, signOut }}>
      <MsalProvider instance={msalInstance}>{children}</MsalProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
