import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import './styles.css'; // Import the CSS file

// Set the app element for react-modal
Modal.setAppElement('#root');

const Approvals = () => {
  const [csvData, setCsvData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedSalesOrder, setSelectedSalesOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uniqSO = new Set();

  useEffect(() => {
    fetch('https://oms.eyantra.com/api/get-master-csv')
      .then(response => response.json())
      .then(data => {
        setCsvData(data.data);
        setFilteredData(data.data); // Initially display all data
      })
      .catch(error => console.error('Error fetching CSV data:', error));
  }, []);

  const filterData = useCallback((term) => {
    const salesOrderNoIndex = csvData[0]?.indexOf('Sales Order No'); // Find the index of the Sales Order No column
    if (salesOrderNoIndex !== -1) {
      const filtered = csvData.filter((row, index) => {
        // Include headers and rows where the salesOrderNo matches the search term
        return index === 0 || row[salesOrderNoIndex].toLowerCase().includes(term.toLowerCase());
      });
      setFilteredData(filtered);
    }
  }, [csvData]);

  useEffect(() => {
    if (searchTerm) {
      filterData(searchTerm);
    } else {
      setFilteredData(csvData); // Reset to all data when search is cleared
    }
  }, [searchTerm, csvData, filterData]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterData(e.target.value);
  };

  const openApprovalDialog = (salesOrderNo) => {
    const salesOrderNoIndex = csvData[0]?.indexOf('Sales Order No');
    if (salesOrderNoIndex !== -1) {
      const items = csvData.filter((row, index) => index === 0 || row[salesOrderNoIndex] === salesOrderNo); // Filter rows by Sales Order No
      setSelectedSalesOrder(items);
      setIsModalOpen(true);
    }
  };

  const closeApprovalDialog = () => {
    setIsModalOpen(false);
    setSelectedSalesOrder(null);
  };

  const handleApproval = (status) => {
    if (selectedSalesOrder && selectedSalesOrder.length > 1) {
      const salesOrderNo = selectedSalesOrder[1][csvData[0].indexOf('Sales Order No')];

      fetch('https://oms.eyantra.com/api/update-approval-status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          salesOrderNo,
          status,
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Update the local state to reflect the changes
          const updatedData = csvData.map(row => {
            if (row[csvData[0].indexOf('Sales Order No')] === salesOrderNo) {
              row[csvData[0].indexOf('Approved')] = status ? 'true' : 'false';
            }
            return row;
          });
          setCsvData(updatedData);
          setFilteredData(updatedData);
        }
        closeApprovalDialog();
      })
      .catch(error => console.error('Error updating approval status:', error));
    }
  };

  return (
    <div className="dashboard-container">
      <h2>Approval Dashboard</h2>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by Sales Order No"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {filteredData.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Approved</th>
                <th>Date</th>
                <th>Client Name</th>
                <th>Payment Terms</th>
                <th>Sales Order No</th>
                <th>Sales POC</th>
              </tr>
            </thead>
            <tbody>

              {filteredData.slice(1).map((row, rowIndex) =>
              {
                const salesOrderNo = row[csvData[0].indexOf('Sales Order No')];
                if (uniqSO.has(salesOrderNo)) {
                  return null;
                } else {
                  uniqSO.add(salesOrderNo);
                  return (
                    <tr key={rowIndex} className="highlight-on-hover" onClick={() => openApprovalDialog(salesOrderNo)}>
                      <td>{row[csvData[0].indexOf('Approved')]}</td>
                      <td>{row[csvData[0].indexOf('Date')]}</td>
                      <td>{row[csvData[0].indexOf('Client Name')]}</td>
                      <td>{row[csvData[0].indexOf('Payment Terms')]}</td>
                      <td>{salesOrderNo}</td>
                      <td>{row[csvData[0].indexOf('Sales POC')]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p>Loading data...</p>
      )}

      {/* Approval Dialog */}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeApprovalDialog}
        contentLabel="Approval Dialog"
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        <div className="dashboard-container">
          <div className="approval-dialog">
            {selectedSalesOrder && selectedSalesOrder.length > 1 ? (
              <>
                <h3>Approve or Reject Sales Order No: {selectedSalesOrder[1][csvData[0].indexOf('Sales Order No')]}</h3>
                <table>
                  <thead>
                    <tr>
                      {csvData[0]?.map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSalesOrder.slice(1).map((item, rowIndex) => (
                      <tr key={rowIndex}>
                        {item.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="approve-buttons">
                  <button onClick={() => handleApproval(true)}>Approve All</button>
                  <button onClick={() => handleApproval(false)}>Reject All</button>
                </div>
              </>
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Approvals;
