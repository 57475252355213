import React from 'react';
import { useAuth } from './AuthProvider';

const authHOC = (WrappedComponent, requiredRole) => {
  return (props) => {
    const { roles } = useAuth();

    if (roles.includes(requiredRole)) {
      return <WrappedComponent {...props} />;
    } else {
      return <div>You do not have access to this component</div>;
    }
  };
};

export default authHOC;