import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import UserInput from './UserInput';
import Dashboard from './Dashboard';
import Approvals from './Approvals';
import Login from './Login';
import ProtectedRoute from './ProtectedRoute';
import { useAuth, AuthProvider } from './AuthProvider';
import './styles.css';
import authHOC from './AuthHOC';


const ManagedApprovals = authHOC(Approvals, 'Manager');
const ManagedDashboard = authHOC(Dashboard, 'Manager');

const App = () => {
  const { account, signOut } = useAuth();
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <nav className="navbar">
            <div className="nav-links">
                <Link to="/" className="nav-link">New Cost Sheet</Link>
                <Link to="/approvals" className="nav-link">Approvals</Link>
                <Link to="/dashboard" className="nav-link">Dashboard</Link>
            </div>
            {account && (<button className="sign-out-button" onClick={signOut}>Sign Out</button>)}
          </nav>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<UserInput />} />
              <Route path="/approvals" element={<ManagedApprovals />} />
              <Route path="/dashboard" element={<ManagedDashboard />} />
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
