import React, { useState, useRef, useEffect } from 'react';
import './userinput.css';

const UserInput = () => {
  const initialItemState = {
    details: '',
    qty: '',
    gst: '',
    pp: '',
    nlc: '',
    sp: '',
    nsp: '',
    totalPp: '',
    totalSp: '',
    margin: '',
    marginPercent: '',
    vendorName: '',
    vendorPaymentTerms: '',
  };

  const [formData, setFormData] = useState({
    date: '',
    clientName: '',
    clientPaymentTerms: '',
    salesOrderNo: '',
    salesPOC: '',
    items: [initialItemState],
    transportationTotalPp: '',
    customizationTotalPp: '',
    marketingTotalPp: '',
    totalPP: '',
    totalSP: '',
    totalMargin: '',
    totalMarginPercent: ''
  });

  const [collapsedItems, setCollapsedItems] = useState([]);
  const downloadLinkRef = useRef(null);

  const [showCustomClientPaymentTerm, setShowCustomClientPaymentTerm] = useState(false);
  const [showCustomVendorPaymentTerm, setShowCustomVendorPaymentTerm] = useState(false);

  const [customClientPaymentTerm, setCustomClientPaymentTerm] = useState('');
  const [customVendorPaymentTerm, setCustomVendorPaymentTerm] = useState('');

useEffect(() => {
    const transportationTotalPp = parseFloat(formData.transportationTotalPp) || 0;
    const customizationTotalPp = parseFloat(formData.customizationTotalPp) || 0;
    const marketingTotalPp = parseFloat(formData.marketingTotalPp) || 0;

    const totalPP = formData.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
    const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
    const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
    const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;

    setFormData(prevState => ({
      ...prevState,
      totalPP: totalPP.toFixed(2),
      totalSP: totalSP.toFixed(2),
      totalMargin: totalMargin.toFixed(2),
      totalMarginPercent: totalMarginPercent.toFixed(2),
    }));
  }, [formData.items, formData.transportationTotalPp, formData.customizationTotalPp, formData.marketingTotalPp]);

const handleFormDataChange = (field, value) => {
  setFormData(prevState => ({
    ...prevState,
    [field]: value
  }));

  if (field === 'clientPaymentTerms') {
    setShowCustomClientPaymentTerm(value === 'custom');
  }
};


  const handleItemChange = (index, field, value) => {
    const updatedItems = formData.items.map((item, idx) => {
      if (idx === index) {
        const updatedItem = { ...item, [field]: value };

        if (field === 'vendorPaymentTerms') {
          setShowCustomVendorPaymentTerm(value === 'custom');
        }

        // Perform calculations
        const pp = parseFloat(updatedItem.pp) || 0;
        const sp = parseFloat(updatedItem.sp) || 0;
        const gst = parseFloat(updatedItem.gst) || 0;
        const qty = parseInt(updatedItem.qty) || 0;

        const nlc = pp + (pp * gst / 100);
        const nsp = sp + (sp * gst / 100);
        const totalPp = nlc * qty;
        const totalSp = nsp * qty;
        const margin = totalSp - totalPp;
        const marginPercent = totalPp ? (margin / totalPp) * 100 : 0;

        updatedItem.nlc = nlc.toFixed(2);
        updatedItem.nsp = nsp.toFixed(2);
        updatedItem.totalPp = totalPp.toFixed(2);
        updatedItem.totalSp = totalSp.toFixed(2);
        updatedItem.margin = margin.toFixed(2);
        updatedItem.marginPercent = marginPercent.toFixed(2);

        return updatedItem;
      }
      return item;
    });

    setFormData(prevState => ({
      ...prevState,
      items: updatedItems,
    }));

    calculateTotals();
  };

  const calculateTotals = () => {
      const transportationTotalPp = parseFloat(formData.transportationTotalPp) || 0;
      const customizationTotalPp = parseFloat(formData.customizationTotalPp) || 0;
      const marketingTotalPp = parseFloat(formData.marketingTotalPp) || 0;

      const totalPP = formData.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
      const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
      const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
      const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;

      setFormData(prevState => ({
        ...prevState,
        totalPP: totalPP.toFixed(2),
        totalSP: totalSP.toFixed(2),
        totalMargin: totalMargin.toFixed(2),
        totalMarginPercent: totalMarginPercent.toFixed(2),
      }));
    };

  const addItem = () => {
    setFormData(prevState => ({
      ...prevState,
      items: [...prevState.items, initialItemState],
    }));
    setCollapsedItems(prevState => [...prevState, false]);
  };

  const removeItem = (index) => {
    setFormData(prevState => ({
      ...prevState,
      items: prevState.items.filter((_, idx) => idx !== index),
    }));
    setCollapsedItems(prevState => prevState.filter((_, idx) => idx !== index));
     calculateTotals();
  };

  const toggleCollapse = (index) => {
    setCollapsedItems(prevState => prevState.map((item, idx) => idx === index ? !item : item));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    generateCSV(formData);
    await updateMasterCSV(formData);
    clearForm();
  };

const generateCSV = (data) => {
  const headers = [
    ['eYantra Pvt Ltd'],
    ['COST SHEET'],
    ['Date', data.date],
    ['Name of the Client', data.clientName],
    ['Payment Terms', data.clientPaymentTerms === 'custom' ? customClientPaymentTerm : data.clientPaymentTerms],
    ['Sales Order No', data.salesOrderNo],
    ['Sales POC', data.salesPOC],
    ['Details', 'Qty', 'GST', 'PP', 'NLC', 'SP', 'NSP', 'Total PP', 'Total SP', 'Margin', 'Margin %', 'Vendor', 'Vendor Payment Terms'],
  ];

  const rows = data.items.map(item => [
    item.details,
    item.qty,
    item.gst,
    item.pp,
    item.nlc,
    item.sp,
    item.nsp,
    item.totalPp,
    item.totalSp,
    item.margin,
    item.marginPercent,
    item.vendorName,
    item.vendorPaymentTerms === 'custom' ? customVendorPaymentTerm : item.vendorPaymentTerms,
  ]);

//  useEffect(() => {
//    const transportationTotalPp = parseFloat(formData.transportationTotalPp) || 0;
//    const customizationTotalPp = parseFloat(formData.customizationTotalPp) || 0;
//    const marketingTotalPp = parseFloat(formData.marketingTotalPp) || 0;
//
//    const totalPP = formData.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
//    const totalSP = formData.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
//    const totalMargin = formData.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
//    const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;
//
//    setFormData(prevState => ({
//      ...prevState,
//      totalPP: totalPP.toFixed(2),
//      totalSP: totalSP.toFixed(2),
//      totalMargin: totalMargin.toFixed(2),
//      totalMarginPercent: totalMarginPercent.toFixed(2),
//    }));
//  }, [formData.items, formData.transportationTotalPp, formData.customizationTotalPp, formData.marketingTotalPp]);


  const transportationTotalPp = parseFloat(data.transportationTotalPp) || 0;
  const customizationTotalPp = parseFloat(data.customizationTotalPp) || 0;
  const marketingTotalPp = parseFloat(data.marketingTotalPp) || 0;

  const totalPP = data.items.reduce((total, item) => total + parseFloat(item.totalPp), 0) + transportationTotalPp + customizationTotalPp + marketingTotalPp;
  const totalSP = data.items.reduce((total, item) => total + parseFloat(item.totalSp), 0);
  const totalMargin = data.items.reduce((total, item) => total + parseFloat(item.margin), 0) - transportationTotalPp - customizationTotalPp - marketingTotalPp;
  const totalMarginPercent = totalPP ? (totalMargin / totalPP) * 100 : 0;

  const additionalRows = [
    ['Transportation', '', '', '', '', '', '', transportationTotalPp.toFixed(2), '', (-transportationTotalPp).toFixed(2), '-100%', '', ''],
    ['Customization', '', '', '', '', '', '', customizationTotalPp.toFixed(2), '', (-customizationTotalPp).toFixed(2), '-100%', '', ''],
    ['Marketing Promotions', '', '', '', '', '', '', marketingTotalPp.toFixed(2), '',(-marketingTotalPp).toFixed(2), '-100%', '', ''],
  ];

  const totalRow = [
    'Total',
    '',
    '',
    '',
    '',
    '',
    '',
    totalPP.toFixed(2),
    totalSP.toFixed(2),
    totalMargin.toFixed(2),
    totalMarginPercent.toFixed(2),
    '',
    ''
  ];

  const csvContent = [...headers, ...rows, ...additionalRows, totalRow]
    .map(e => e.join(","))
    .join("\n");

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  downloadLinkRef.current.href = url;
  downloadLinkRef.current.download = `cost_sheet_${formData.clientName}_${formData.salesOrderNo}_${formData.date}.csv`;
  downloadLinkRef.current.click();
};


  const updateMasterCSV = async (data) => {
    const response = await fetch('http://localhost:5000/api/update-master-csv', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      console.log('Master CSV updated successfully');
    } else {
      console.error('Failed to update master CSV');
    }
  };

  const clearForm = () => {
    setFormData({
      date: '',
      clientName: '',
      clientPaymentTerms: '',
      salesOrderNo: '',
      salesPOC: '',
      items: [initialItemState],
    });
    setCollapsedItems([]);
    setShowCustomClientPaymentTerm(false);
    setShowCustomVendorPaymentTerm(false);
    setCustomClientPaymentTerm('');
    setCustomVendorPaymentTerm('');
  };

  return (
    <form onSubmit={handleSubmit} className="csv-form">
      <div className="csv-header">
        <h2>eYantra Pvt Ltd</h2>
        <h3>COST SHEET</h3>
      </div>
      <div className="form-group">
        <label>
          Date:
          <input type="date" name="date" value={formData.date} onChange={(e) => handleFormDataChange('date', e.target.value)} required />
        </label>
        <label>
          Name of the Client:
          <input type="text" name="clientName" value={formData.clientName} onChange={(e) => handleFormDataChange('clientName', e.target.value)} required />
        </label>
        <label>
          Client Payment Terms:
          <select name="clientPaymentTerms" value={formData.clientPaymentTerms} onChange={(e) => handleFormDataChange('clientPaymentTerms', e.target.value)} required>
            <option value="">Select Payment Term</option>
            <option value="advance">Advance</option>
            <option value="against-delivery">Against Delivery</option>
            <option value="10-days">10 days</option>
            <option value="15-days">15 days</option>
            <option value="30-days">30 days</option>
            <option value="45-days">45 days</option>
            <option value="60-days">60 days</option>
            <option value="custom">Custom</option>
          </select>
          {showCustomClientPaymentTerm && (
            <input
              type="text"
              name="customClientPaymentTerm"
              value={customClientPaymentTerm}
              onChange={(e) => setCustomClientPaymentTerm(e.target.value)}
              placeholder="Enter custom payment term"
              required
            />
          )}
        </label>
        <label>
          Sales Order No:
          <input type="text" name="salesOrderNo" value={formData.salesOrderNo} onChange={(e) => handleFormDataChange('salesOrderNo', e.target.value)} required />
        </label>
        <label>
          Sales POC:
          <input type="text" name="salesPOC" value={formData.salesPOC} onChange={(e) => handleFormDataChange('salesPOC', e.target.value)} required />
        </label>
      </div>

      <table className="csv-table">
        <thead>
          <tr>
            <th>Details</th>
            <th>Qty</th>
            <th>GST</th>
            <th>PP</th>
            <th>NLC</th>
            <th>SP</th>
            <th>NSP</th>
            <th>Total PP</th>
            <th>Total SP</th>
            <th>Margin</th>
            <th>Margin %</th>
            <th>Vendor</th>
            <th>Vendor Payment Terms</th>
            <th>Action</th>
          </tr>
        </thead>
      <tbody>
        {formData.items.map((item, index) => (
          <tr key={index}>
            <td>
              <input type="text" value={item.details} onChange={(e) => handleItemChange(index, 'details', e.target.value)} required />
            </td>
            <td>
              <input type="number" value={item.qty} onChange={(e) => handleItemChange(index, 'qty', e.target.value)} required />
            </td>
            <td>
              <input type="number" value={item.gst} onChange={(e) => handleItemChange(index, 'gst', e.target.value)} required />
            </td>
            <td>
              <input type="number" value={item.pp} onChange={(e) => handleItemChange(index, 'pp', e.target.value)} required />
            </td>
            <td className="span-style">
              <span>{item.nlc}</span>
            </td>
            <td>
              <input type="number" value={item.sp} onChange={(e) => handleItemChange(index, 'sp', e.target.value)} required />
            </td>
            <td className="span-style">
              <span>{item.nsp}</span>
            </td>
            <td className="span-style">
              <span>{item.totalPp}</span>
            </td>
            <td className="span-style">
              <span>{item.totalSp}</span>
            </td>
            <td className="span-style">
              <span>{item.margin}</span>
            </td>
            <td className="span-style">
              <span>{item.marginPercent}</span>
            </td>
            <td>
              <input type="text" value={item.vendorName} onChange={(e) => handleItemChange(index, 'vendorName', e.target.value)} required />
            </td>
            <td>
              <select name="vendorPaymentTerms" value={item.vendorPaymentTerms} onChange={(e) => handleItemChange(index, 'vendorPaymentTerms', e.target.value)} required>
                <option value="">Select Payment Term</option>
                <option value="advance">Advance</option>
                <option value="against-delivery">Against Delivery</option>
                <option value="10-days">10 days</option>
                <option value="15-days">15 days</option>
                <option value="30-days">30 days</option>
                <option value="45-days">45 days</option>
                <option value="60-days">60 days</option>
                <option value="custom">Custom</option>
              </select>
              {showCustomVendorPaymentTerm && (
                <input
                  type="text"
                  name="customVendorPaymentTerm"
                  value={customVendorPaymentTerm}
                  onChange={(e) => setCustomVendorPaymentTerm(e.target.value)}
                  placeholder="Enter custom payment term"
                  required
                />
              )}
            </td>
            <td>
              <button type="button" onClick={() => removeItem(index)}>Remove</button>
            </td>
          </tr>
        ))}
        <tr>
          <td>Transportation</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <input type="number" value={formData.transportationTotalPp} onChange={(e) => handleFormDataChange('transportationTotalPp', e.target.value)} required />
          </td>
          <td></td>
          <td className="span-style">
            <span >{-formData.transportationTotalPp}</span>
          </td>
          <td>-100%</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Customization</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <input type="number" value={formData.customizationTotalPp} onChange={(e) => handleFormDataChange('customizationTotalPp', e.target.value)} required />
          </td>
          <td></td>
          <td className="span-style">
            <span>{-formData.customizationTotalPp}</span>
          </td>
          <td>-100%</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Marketing</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <input type="number" value={formData.marketingTotalPp} onChange={(e) => handleFormDataChange('marketingTotalPp', e.target.value)} required />
          </td>
          <td></td>
          <td className="span-style">
            <span> {-formData.marketingTotalPp} </span>
          </td>
          <td>-100%</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
            <td>Total</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="span-style">
                <span>{formData.totalPP}</span>
            </td>
            <td className="span-style">
                <span>{formData.totalSP}</span>
            </td>
            <td className="span-style">
                <span>{formData.totalMargin}</span>
            </td>
            <td className="span-style">
                <span>{formData.totalMarginPercent}</span>
            </td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
      </tbody>
      </table>

      <button type="button" onClick={addItem}>Add Item</button>
      <button type="submit">Generate CSV</button>

      <a ref={downloadLinkRef} style={{ display: 'none' }}>Download CSV</a>
    </form>
  );
};

export default UserInput;
